@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css?family=Poppins:500");

@font-face {
  font-family: "PP Hatton-Bold";
  src: url("../public/hatton-font-family//PPHattonBold700.otf") format("opentype");
}

@font-face {
  font-family: "PP Hatton-Medium";
  src: url("../public/hatton-font-family/PPHattonMedium500.otf") format("opentype");
}

@font-face {
  font-family: "PP Hatton-thin";
  src: url("../public/hatton-font-family/PPHattonUltralight200.otf") format("opentype");
}

@font-face {
  font-family: "Big-Caslon";
  src: url("./assets/BigCaslonMedium.ttf") format("opentype");
}
.timeline {
  position: absolute;
  bottom: -2vw;
  left: 0;
  width: 100%;
}

.line {
  position: absolute;
  height: 2px;
  background-color: #6D744C;
  top: 50%;
  left: 0;
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #6D744C;
  top: 50%;
  transform: translate(-50%, -50%);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
    cursor: none;
}




.home-font{
  font-family: "PP Hatton-thin" serif;
}


.aboutpicture{

}
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.zoomed-image {
  max-width: 90vw;
  max-height: 90vh;
}




.new-component {
  transition: transform 0.5s ease;
}

.new-component.zoom-in {
  transform: scale(1);
}

.new-component.zoom-out {
  transform: scale(0.8);
}

body {
  background-color: #F7F3EA;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}




.cursorFollower {
  z-index: 111111;
  position: fixed;
  pointer-events: none;
}



/* Animation for text sliding from right to left */
@keyframes text-slide {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation for video sliding from bottom to top */
@keyframes video-slide {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply animations to respective elements */
.animate-text-slide {
  animation: text-slide 1s ease-in-out forwards;
}

.animate-video-slide {
  animation: video-slide 1s ease-in-out forwards;
}

.custom-scrollbar {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #333333 #f0f0f0;
  /* For Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* Color of the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #333333;
  /* Color of the thumb */
  border-radius: 6px;
  /* Roundness of the thumb */
  border: 3px solid #f0f0f0;
  /* Border around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
  /* Color of the thumb on hover */
}

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}


::-webkit-scrollbar-track {
  background: #000000; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background: #fff1d1; /* Color of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #fff1d1; /* Color of the thumb on hover */
}



.svggg {
  fill: currentColor;
  height: auto;
  max-width: 66vmin;
  transform-origin: center;
  width: 66%;
}

.gallery-container {
  display: flex;
  overflow-x: auto;
  /* Allow horizontal scrolling */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
}

.gallery-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}



.popup-overlay {
  z-index: 3000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(199, 199, 199, 0.368);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc00;
  -webkit-transition: .4s;
  border: 1px solid;
  transition: .4s;
 
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: black;
  transition: .4s; 
}


.input:checked + .slider {
  box-shadow: 5px 5px 10px #000000;
  background-color: #fafafa;
}


.input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



.chevron {
  cursor: pointer;
  position: absolute;
  width: 5vh;
  height: 1vh;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #000000;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;

  }

  33% {
    opacity: 1;
    transform: translateY(30px);
  }

  67% {
    opacity: 1;
    transform: translateY(40px);
  }

  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}
